type SiteMetaType = { [key: string]: string }
type SiteSupportType = { [key: string]: boolean }
type SiteMenuType = { [key: string]: { name: string, url: string }[] }

export const siteMeta: SiteMetaType = {
    siteName: "BET1618",
    siteDescription: "BET1618 CASINO"
};

export const siteLocale: string = "ko";
export const siteTimeZone: string = "Asia/Seoul";

export const siteSupport: SiteSupportType = {
    useMultiLanguage: true,

    //supported pages
    useAsset: true,
    useCasino: true,
    useCustomerCenter: true,
    useDeposit: true,
    useDepositHistory: true,
    useEvent: true,
    useHoldem: false,
    useMessage: true,
    useMinigame: true,
    useNotice: true,
    usePasswordChange: true,
    useSlot: true,
    useSports: true,
    useWithdrawal: true,
    useWithdrawalHistory: true,
};

export const siteMenu: SiteMenuType = {
    menu: [
        { name: "SLOT", url: "/slot/" },
        { name: "CASINO", url: "/casino/" },
        { name: "HOLDEM", url: "/holdem/" },
        { name: "SPORTS", url: "/sports/" },
        { name: "MINIGAME", url: "/minigame/" },
        { name: "DEPOSIT", url: "/deposit/" },
        { name: "DEPOSIT_HISTORY", url: "/deposit-history/" },
        { name: "WITHDRAWAL", url: "/withdrawal/" },
        { name: "WITHDRAWAL_HISTORY", url: "/withdrawal-history/" },
        { name: "NOTICE", url: "/notice/" },
        { name: "EVENT", url: "/event/" },
        { name: "PASSWORD_CHANGE", url: "/password-change/" },
        { name: "MESSAGE", url: "/message/" },
        { name: "CUSTOMER_CENTER", url: "/customer-center/" },
    ]
}

export const mobileFooter: SiteMenuType = {
    menu: [
        { name: "HOME", url: "/" },
        { name: "SLOT", url: "/slot/" },
        { name: "CASINO", url: "/casino/" },
        { name: "SPORTS", url: "/sports/" },
        { name: "ASSET", url: "/asset/" },
    ]
}