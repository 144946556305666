"use client"

import api from "@/utils/api"
import { FormEvent, useContext, useEffect, useRef, useState, useCallback } from "react"
import { AuthContext } from "@/contexts/authContext"
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import customAlert from "@/utils/customAlert";
import Mobile_SignupModal from "./Mobile_SighupModal";
import { LanguageContext } from "@/contexts/languageContext";
import Image from "next/image";
import { useTranslation } from 'next-i18next';

import { siteMeta } from "@/siteConfig/settings/siteSetting";

export default function Mobile_SigininModal({ show, setShow, isRanding }: { show: boolean, setShow: Function, isRanding?: boolean }) {
    const { languageCode: lang } = useContext(LanguageContext);
    const { user, login } = useContext(AuthContext) ?? {};
    const inputUserNameRef = useRef<HTMLInputElement>(null)
    const [error, setError] = useState<any>()
    const [securityAnswer, setSecurityAnswer] = useState<number>()
    const [securityReRend, setSecurityReRend] = useState<boolean>(false)

    const [registerShow, setRegisterShow] = useState<boolean>(false)
    const { t } = useTranslation();

    const handleClose = useCallback(() => {
        setShow(false)
        setError(undefined)
    }, [setShow, setError]);

    function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const formData = new FormData(e.currentTarget)
        if (formData.get('security') && securityAnswer) {
            if (Number(formData.get('security')) !== securityAnswer) {
                customAlert(t("ACCOUNT.NOT_VALID_SECURITY_CODE"), 'warning')
                setSecurityReRend(!securityReRend)
                return false;
            }
        } else {
            customAlert(t("ACCOUNT.REQUIRED_SECURITY_CODE"), 'warning')
            setSecurityReRend(!securityReRend)
            return false;
        }
        api("/api/auth/login/", { method: "POST", body: formData })
            .then(res => res.json())
            .then(data => login(data))
            .catch(res => res.json().then((data: any) => setError(data)))
    }
    useEffect(() => {
        if (show) {
            inputUserNameRef.current?.focus()
        }
    }, [show])
    useEffect(() => {
        if (user.is_authenticated) {
            handleClose()
        }
    }, [user, handleClose])

    useEffect(() => {
        if (!t) return;
    }, [t])

    return (
        <>
            <Modal show={show}
                onHide={!isRanding ? handleClose : undefined}
                backdrop={!isRanding ? true : false}
                scrollable={true}
                size={'xl'}
                aria-labelledby="contained-modal-title-vcenter"
                className="login-modal-mobile"
            >

                <Modal.Body>
                    <div className="modal-form">
                        <div className="login-modal-header">
                            <span className="site-name">{siteMeta.siteName}</span>
                            <div className="close-window">
                                <FontAwesomeIcon icon={faXmark} onClick={handleClose} />
                            </div>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="form-row">
                                <input name="username" type="text" ref={inputUserNameRef} placeholder={t("ACCOUNT.ID")}
                                    className={error?.username && 'error'} />
                                {error?.username && error.username.map((message: string) => <ErrorMessage key={message} message={message} />)}
                            </div>
                            <div className="form-row">
                                <input name="password" type="password" placeholder={t("ACCOUNT.PASSWORD")}
                                    className={error?.password && 'error'}
                                    onClick={e => setError(undefined)} />
                                {error?.password && error.password.map((message: string) => <ErrorMessage key={message} message={message} />)}
                            </div>
                            <div className="form-row">
                                <SecurityLayout reRend={securityReRend} setSecurityAnswer={setSecurityAnswer} />
                            </div>
                            <div className="submit-box">
                                <button type="submit"><span>{t("HEAD.LOGIN")}</span></button>
                                <span className="signup-link" onClick={e => { setRegisterShow(true); handleClose() }}>{t("ACCOUNT.SIGNUP_PLACEHOLDER")}</span>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
            <Mobile_SignupModal show={registerShow} setShow={setRegisterShow} />
        </>
    )
}

function SecurityLayout({ reRend, setSecurityAnswer }: { reRend: boolean, setSecurityAnswer: Function }) {
    const { languageCode: lang } = useContext(LanguageContext)
    const [answer, setAnswer] = useState<number>()
    const { t } = useTranslation();

    function securitySet() {

        let answer = Math.floor((Math.random() * 1000000))
        let loop = 0
        while (answer < 100000) {
            answer = Math.floor((Math.random() * 1000000))
            loop++;
        }

        setAnswer(answer)
        setSecurityAnswer(answer)
    }

    useEffect(() => {
        securitySet()
    }, [reRend])

    return (
        <div className="security-layout">
            <div className="security-code">{answer}</div>
            <input type="number" name={`security`} placeholder={t("ACCOUNT.REQUIRED_SECURITY_CODE")} className="security-input" />
        </div>
    )
}